import { ADD_ITEM, REMOVE_ITEM, UPDATE_QUANTITY } from "./OrderTypes"

const initialState = {
    orderList: []
}

const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_ITEM: return {
            ...state,
            orderList: [...state.orderList, action.payload]
        }
        case UPDATE_QUANTITY: return {
            ...state,
            orderList: state.orderList.map(
                (orderList) => orderList.id === action.payload[0] ? { ...orderList, quantity: action.payload[1] } : orderList
            )
        }
        case REMOVE_ITEM: return {
            ...state,
            orderList: state.orderList.filter((orderList) => orderList.id !== action.payload)
        }
        default: return state
    }
}

export default orderReducer
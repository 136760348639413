import React, { Component } from 'react'
import axios from 'axios';
import buttertoffee from './img/buttertoffee.png'
import { Link, MemoryRouter, Route, Router, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { addItem } from './redux'
import Product from './Product';
import ProductCat from './ProductCat';

export class ProductsList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            currentItems: []
        }
    }

    componentDidMount() {
        console.log(this.props)
        axios.get(`${this.props.api}/api/collections/get/products?token=${this.props.acctok}`)
            .then(res => {
                const current = res.data.entries.filter(x => x.category._id === this.props.cat._id)
                this.setState({ currentItems: current })
            })
            .catch(err => {
                console.log(err)
            })
    }

    addToOrder = (id) => {
        const order = {
            id: id,
            quantity: 1
        }
        if (this.props.orderList.some(e => e.id === order.id)) {
            console.log('already added');
        } else {
            this.props.addItem(order)
        }

        // if (this.props.orderList.length === 0 || this.props.orderList.find(x => x.id !== id)) {
        //     this.props.addItem(order)
        // }
    }

    render() {
        const { currentItems } = this.state

        const productList = currentItems.map(product => (
            <div key={product._id} className='col-12 col-lg-6'>
                <div className='card product-card mb-3'>
                    <div className='row g-0'>
                        <div className='col-12 col-md-5'>
                            <img src={`${this.props.api}/${product.mainImage.path}`} className='img-fluid rounded-start' alt={product.name} />
                        </div>
                        <div className='col-12 col-md-7'>
                            <div className='card-body'>
                                <Link to={`product/${product._id}`}><h1 className='card-title product-title'>{product.name} - {product.size.display}</h1></Link>
                                <h5 className='card-text product-type'>{product.type.display}</h5>
                                {product.sprice ? <><h2 className='product-price'> <span className='slashed'>$ {product.price}</span> <span className='special'>$ {product.sprice}</span></h2>
                                    <p style={{ marginBottom: '.2em' }}> <span className='promo'> PROMOTIONAL PRICE </span></p></>
                                    :
                                    <h2 className='product-price'>$ {product.price}</h2>
                                }
                                {parseInt(product.quantity) === 0 ?
                                    <p className='not-in-stock'>Out of Stock</p>
                                    :
                                    parseInt(product.quantity) <= parseInt(product.threshold) ? <p className='not-in-stock'>Limited Quantity Available</p> : <p className='in-stock'>In Stock</p>                                
                                // <p className='in-stock'>In Stock</p>
                                }
                                <input type='button' className={`btn btn-order`} value={this.props.orderList.some(e => e.id === product._id) ? 'ADDED TO ORDER' : 'ADD TO ORDER'} onClick={() => this.addToOrder(product._id)} disabled={this.props.orderList.some(e => e.id === product._id) || parseInt(product.quantity) === 0} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))

        return (
            <>
                <ProductCat api={this.props.api} acctok={this.props.acctok} />
                <div className='row mt-4'>
                    {productList}
                </div>
                <ProductCat api={this.props.api} acctok={this.props.acctok} />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        orderList: state.orderReducer.orderList
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addItem: (...args) => dispatch(addItem(...args))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductsList)
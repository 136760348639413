import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { connect } from 'react-redux';
import { addItem } from './redux'
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css'

function Product(props) {

    useEffect(() => {
        getItem()
    })

    const [product, setProduct] = useState()

    const addToOrder = (id) => {
        const order = {
            id: id,
            quantity: 1
        }
        if (props.orderList.some(e => e.id === order.id)) {
            console.log('already added');
        } else {
            props.addItem(order)
        }
    }

    const getItem = () => {
        axios.get(`${props.api}/api/collections/get/products?token=${props.acctok}`)
            .then(res => {
                const current = res.data.entries.filter(x => x._id === props.match.params.id)
                setProduct(current)
            })
            .catch(err => {
                console.log(err)
            })
    }

    // const pro = product[0]
    // console.log(pro)
    // console.log(product);

    let pro, pictures, mainImage

    if (product && product[0]) {
        pro = product[0]
        if (pro && pro.pictures) {
            pictures = pro.pictures.map(x => (
                { original: `${props.api}/${x.path}`, thumbnail: `${props.api}/${x.path}` }
            ))
        }
        mainImage = [{ original: `${props.api}/${pro.mainImage.path}`, thumbnail: `${props.api}/${pro.mainImage.path}` }]
        if (pictures == null) {
            pictures = false
        } else {
            pictures = [...mainImage, ...pictures]
        }
    }

    // console.log(pictures);

    return (
        <>
            {pro == null ? 'Loading...' :
                <div className='row mt-4'>
                    <div className='col-12 col-md-5'>
                        {/* <img className='img-fluid' src={`${props.api}/${pro.mainImage.path}`} /> */}
                        {pictures == null ? 'Loading...' :
                            pictures === false ?
                                <img className='img-fluid product-image' src={`${props.api}/${pro.mainImage.path}`} />
                                :
                                <ImageGallery
                                    items={pictures}
                                    showPlayButton={false}
                                    showNav={false}
                                />
                        }
                    </div>
                    <div className='col-12 col-md-7 product-description'>
                        <h1>{pro.name}</h1>
                        <h5>{pro.type.display}</h5>
                        <h5>Size: {pro.size.display}</h5>
                        {pro.sprice ? <><h2 className='product-price-single'> <span className='slashed'>$ {pro.price}</span> <span className='special'>$ {pro.sprice}</span></h2>
                            <p style={{ marginBottom: '.2em' }}> <span className='promo'> PROMOTIONAL PRICE </span></p></>
                            :
                            <h2>$ {pro.price}</h2>
                        }
                        <h5>{pro.shortDescription}</h5>
                        <h5>Ingredients: {pro.ingredients}</h5>
                        {parseInt(pro.quantity, 10) === 0 ? <p className='not-in-stock'>Out of Stock</p> :
                            parseInt(pro.quantity) <= parseInt(pro.threshold) ? <p className='not-in-stock'>Limited Quantity Available</p> : <p className='in-stock'>In Stock</p>
                        }
                        <input type='button' className={`btn btn-order product-page-single`} value={props.orderList.some(e => e.id === pro._id) ? 'ADDED TO ORDER' : 'ADD TO ORDER'} onClick={() => addToOrder(pro._id)} disabled={props.orderList.some(e => e.id === pro._id) || parseInt(pro.quantity, 10) === 0} />
                    </div>
                </div>
            }
        </>

    )
}

const mapStateToProps = state => {
    return {
        orderList: state.orderReducer.orderList
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addItem: (...args) => dispatch(addItem(...args))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Product)
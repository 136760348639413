import React, { useState } from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import TextField from '@mui/material/TextField'
import * as Yup from 'yup'

function Contact(props) {
    const validationSchema = Yup.object({
        name: Yup.string().required('Please enter your name'),
        email: Yup.string().email('Please enter a valid email address').required('Please enter your email address'),
        subject: Yup.string().required('Please type a subject for us'),
    })

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            subject: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, onSubmitProps) => {
            const data = {
                data: {
                    ...values
                }
            }
            axios.post(`${props.api}/api/collections/save/messages?token=${props.acctok}`, data)
                .then(res => {
                    if (res.status == 200) {
                        setSubmitted('success')
                        submittedMessage()
                    }
                })
                .catch(err => {
                    setSubmitted('failed')
                    submittedMessage()
                    console.log(err);
                })
            // console.log(values);
            onSubmitProps.resetForm()
        }
    })

    const [submitted, setSubmitted] = useState(null)

    const submittedMessage = () => {
        setTimeout(() => {
            setSubmitted(null)
        }, 4000)
    }

    return (
        <div className={props.css_class}>
            <h1 style={{ textAlign: 'center' }}>Send us a message</h1>
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    fullWidth
                    name='name'
                    label='Name'
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    variant='standard'
                />
                <TextField
                    fullWidth
                    name='email'
                    label='Email'
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    variant='standard'
                    className='mt-2'
                />
                <TextField
                    fullWidth
                    name='subject'
                    label='Subject'
                    value={formik.values.subject}
                    onChange={formik.handleChange}
                    error={formik.touched.subject && Boolean(formik.errors.subject)}
                    helperText={formik.touched.subject && formik.errors.subject}
                    variant='standard'
                    className='mt-2'
                />
                <div className='text-center mt-3'>
                    {submitted ?
                        submitted === 'success' ? <span className='sbt-success'>Submitted Successfully</span> : <span className='sbt-failure'>Error, please try again later</span> : null}
                </div>
                <div className='text-center'>
                    <button type='button' type='submit' className='btn btn-contact-message'>SUBMIT</button>
                </div>
            </form>
        </div>
    )
}

export default Contact
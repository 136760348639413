import React, { useState } from 'react'
import logo from '../img/dpllogo.svg'
import wlogo from '../img/dplfooterlogo.svg'
import home from '../img/home.svg'
import beverages from '../img/beverages.svg'
import wcart from '../img/wcart.svg'
import touch from '../img/touch.svg'
import story from '../img/story.svg'
import cart from '../img/cart.svg'
import ham from '../img/ham.png'
import x from '../img/x.svg'
import { useFormik } from 'formik'
import TextField from '@mui/material/TextField'
import * as Yup from 'yup'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

function Menu(props) {
    const [mobMenu, setMobMenu] = useState(false)
    const [submitted, setSubmitted] = useState(null)

    const validationSchema = Yup.object({
        name: Yup.string().required('Please enter your name'),
        email: Yup.string().email('Please enter a valid email address').required('Please enter your email address'),
    })

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, onSubmitProps) => {
            const data = { data: { ...values } }
            axios.post(`${props.api}/api/collections/save/subscribers?token=${props.acctok}`, data)
                .then(res => {
                    if (res.status == 200) {
                        setSubmitted('success')
                        submittedMessage()
                    }
                })
                .catch(err => {
                    setSubmitted('failed')
                    submittedMessage()
                    console.log(err);
                })
            // console.log(values);
            onSubmitProps.resetForm()
        }
    })

    const submittedMessage = () => {
        setTimeout(() => {
            setSubmitted(null)
        }, 4000)
    }

    return (
        <>
            <div className='container-fluid menu-container'>
                <div className='row align-items-center'>
                    <div className='col-md-3 col-lg-4'>
                        <Link to='/'><img className='img-fluid' src={logo} /></Link>
                    </div>
                    <div className='col-md-9 col-lg-8 menu-list '>
                        <ul>
                            <Link to='/products'><li><h1>Our Products</h1></li></Link>
                            <Link to='/our-story'><li><h1>Our Story</h1></li></Link>
                            <Link to='/contact'><li><h1>Get In Touch</h1></li></Link>
                            <Link to='/cart'><li className='cart-link'><h1>Cart</h1></li></Link>
                            <Link to='/cart'><li className='cart-counter-wrapper'>{props.orderList.length > 0 ? <span className='cart-counter'><p>{props.orderList.length}</p></span> : ''}<img className='cart-img' src={cart} /></li></Link>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='container-fluid mobile-menu-wrapper'>
                <div className='mobile-logo-wrapper'>
                    <Link to='/'><img className='mobile-logo' src={logo} /></Link>
                </div>
                <div className='side-buttons'>
                    <div className='cart-btn mb-2'>
                        <img className='mobile-btn-img' onClick={() => { setMobMenu(true) }} src={ham} />
                    </div>
                    <div className='cart-btn mob-cart'>
                        <Link to='/cart'>{props.orderList.length > 0 ? <div className='mobile-cart-counter'><p>{props.orderList.length}</p></div> : ''}<img className='mobile-btn-img' src={cart} /></Link>
                    </div>
                </div>
            </div>
            {mobMenu ?
                <div className='mobile-menu-main'>
                    <div className='row no-gutters align-items-center mt-4 mb-3'>
                        <div className='col-4 text-center'>
                            <Link to='/' onClick={() => setMobMenu(false)}><img className='img-fluid' src={home} /></Link>
                        </div>
                        <div className='col-4 text-center'>
                            <Link to='/'><img className='img-fluid' src={wlogo} /></Link>
                        </div>
                        <div className='col-4 text-center'>
                            <div className='cart-btn mb-2 close-menu-btn'>
                                <img className='mobile-btn-img' onClick={() => { setMobMenu(false) }} src={x} alt='close menu button' />
                            </div>
                        </div>
                    </div>
                    <div className='row no-gutters align-items-center mobile-menu-items mb-3'>
                        <div className='col-6 pt-4 text-center border-right border-bottom menu-item'>
                            <Link to='/products' onClick={() => setMobMenu(false)}><img className='img-fluid' src={beverages} /></Link>
                            <h3 className='mobile-menu-header'>Our Products</h3>
                        </div>
                        <div className='col-6 pt-4 text-center border-bottom menu-item'>
                            <Link to='/products' onClick={() => setMobMenu(false)}><img className='img-fluid' src={wcart} /></Link>
                            <h3 className='mobile-menu-header'>Cart</h3>
                        </div>
                        <div className='col-6 pt-4 text-center border-right menu-item'>
                            <Link to='/contact' onClick={() => setMobMenu(false)}><img className='img-fluid' src={touch} /></Link>
                            <h3 className='mobile-menu-header'>Get In Touch</h3>
                        </div>
                        <div className='col-6 pt-4 text-center menu-item'>
                            <Link to='/our-story' onClick={() => setMobMenu(false)}><img className='img-fluid' src={story} /></Link>
                            <h3 className='mobile-menu-header'>Our Story</h3>
                        </div>
                    </div>
                    <div className='mx-4'>
                        <div className='text-center footer-subscribe'>
                            <h2>Mailing List</h2>
                            <h5>Subscribe to our mailing list for updates</h5>
                        </div>
                        <form className='footer-sub-form' onSubmit={formik.handleSubmit}>
                            <TextField
                                fullWidth
                                name='name'
                                label='Name'
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                                variant='standard'
                            />
                            <TextField
                                fullWidth
                                name='email'
                                label='Email'
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                variant='standard'
                                className='mt-2'
                            />
                            <div className='text-center mt-3'>
                                {submitted ?
                                    submitted === 'success' ? <span className='sbt-success'>Submitted Successfully</span> : <span className='sbt-failure'>Error, please try again later</span> : null}
                            </div>
                            <div className='text-center mb-4'>
                                <button type='button' type='submit' className='btn btn-footer-message'>SUBSCRIBE</button>
                            </div>
                        </form>
                    </div>
                </div>
                : null}
        </>
    )
}

const mapStateToProps = state => {
    return {
        orderList: state.orderReducer.orderList
    }
}

export default connect(mapStateToProps, null)(Menu)
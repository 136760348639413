import React, { Component } from 'react'
import axios from 'axios'

export class About extends Component {
    constructor(props) {
        super(props)

        this.state = {
            content: ''
        }
    }

    componentDidMount() {
        axios.get(`${this.props.api}/api/singletons/get/about?token=${this.props.acctok}`)
            .then(res => {
                this.setState({ content: res.data.content })
            })
            .catch(err => {
                console.log(err)
            })
    }

    render() {
        const { content } = this.state
        return (
            <div className='about'>
                <h1>About</h1>
                <span className='border-span'></span>
                <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
        )
    }
}

export default About
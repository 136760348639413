import React, { Component } from 'react'
import Products from './Products'

export class DesktopHome extends Component {
  render() {
    return (
      <>
        <Products api={this.props.api} acctok={this.props.acctok} />
      </>
    )
  }
}

export default DesktopHome
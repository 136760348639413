import React, { Component } from 'react'
import axios from 'axios'
import ProductsList from './ProductsList'


export class Products extends Component {
    constructor(props) {
        super(props)

        this.state = {
            defaultProduct: ''
        }
    }

    componentDidMount() {
        axios.get(`${this.props.api}/api/collections/get/products?token=${this.props.acctok}`)
            .then(res => {
                this.setState({ defaultProduct: res.data.entries[0].category._id })
            })
            .catch(err => {
                console.log(err)
            })
    }


    render() {
        const cat = { _id: '727eff12393637817c000230' }

        return (
            <>
                <ProductsList cat={cat} api={this.props.api} acctok={this.props.acctok} />
            </>
        )
    }
}

export default Products

// import React from 'react'
// import axios from 'axios'
// import ProductsList from './ProductsList'

// function Products(props) {

//     const

//   return (
//     <div>Products</div>
//   )
// }

// export default Products
import { createStore, combineReducers } from 'redux'
import orderReducer from './Order/OrderReducer'

const rootReducer = combineReducers({
    orderReducer: orderReducer
})

const store = createStore(rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

export default store
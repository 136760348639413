import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import axios from 'axios'

export class ProductCat extends Component {
    constructor(props) {
        super(props)

        this.state = {
            categories: []
        }
    }

    componentDidMount() {
        axios.get(`${this.props.api}/api/collections/get/productCat?token=${this.props.acctok}`)
            .then(res => {
                this.setState({ categories: res.data.entries })
            })
            .catch(error => {
                console.log(error)
            })
    }
    render() {
        const { categories } = this.state

        const linkList = categories.map(cat => (
            <NavLink key={cat._id} to={cat.name_slug} activeClassName='active-menu-item'><p>{cat.name}</p></NavLink>
        ))
        return (
            <div className='products-list-desktop'>
                {linkList}
            </div>
        )
    }
}

export default ProductCat
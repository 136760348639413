import React, { Component } from 'react'
import Banner from './desktopheader/Banner'
import Menu from './desktopheader/Menu'


export class DesktopHeader extends Component {
  render() {
    return (
      <>
        <Banner />
        <Menu api={this.props.api} acctok={this.props.acctok} />
      </>
    )
  }
}

export default DesktopHeader